.home-nav-items {
  padding: 16px 24px !important;
  color: white !important;
  font-size: 15px;
}

.plain-nav-button {
  font-size: 17px;
  float: right;
}

.home-nav {
  padding: 48px 64px 0;
  background-color: transparent;
  position: absolute;
  right: 0;
}

.home-nav-dropdown {
  padding: 2px 9px;
  margin: 5px 24px;
}

a.home-nav-items:hover {
  background-color: rgba(255, 255, 255, 0.4);
  text-decoration: none !important;
}

a.service-cta:hover {
  text-decoration: none !important;
  background-color: turquoise;
  color: white;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

a:hover {
  cursor: pointer;
}

.text-p {
  color: black;
}

.signup-hero {
  height: 100vh;
  background-color: #000;
  font-size: 16px;
  font-weight: 700;
}

.home-navbar {
  position: absolute;
  top: 32px;
  width: 100%;
  background-color: transparent;
}

.home-nav-btn {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.home-nav-btn.w--current {
  color: #fff;
}

.home-nav-btn.btn {
  margin-left: 18px;
  padding: 12px 32px;
  background-color: #fff;
  color: #000;
  font-weight: 700;
  text-align: center;
}

.home-nav-menu {
  left: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-container {
  position: static;
  display: block;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url("../images/hero-image.png");
  background-position: 0px 0px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  opacity: 1;
}

.div-block {
  width: 250px;
  height: 250px;
  background-color: #fff;
}

.hero-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(189, 195, 199, 0.72);
}

.hero-img {
  height: 700px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px;
}

.posts {
  padding-top: 32px;
  padding-bottom: 150px;
  background-color: #fff;
}

.fit-card {
  width: 350px;
  /* height: 450px; */
  border-style: none;
  border-width: 0px;
  border-color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
}

.fit-image-container {
  position: static;
  height: 360px;
  margin-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: transparent;
}

.fits-seperator {
  height: 1px;
  background-color: #1a1a1a;
  opacity: 0;
}

.dropdown {
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  background-color: #fff;
}

.dropdown-list {
  border: 1px none #000;
  border-radius: 0px;
  background-color: #00f;
}

.dropdown-list.w--open {
  margin-top: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.image-2 {
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png");
  background-position: 0px 0px;
  background-size: 125px;
}

.fits-img {
  width: 100%;
  height: 100%;
}

.posts-container {
  padding-left: 0px;
  max-width: 1200px;
}

.posts-grid {
  display: -ms-grid;
  display: grid;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: start;
  -ms-flex-line-pack: start;
  align-content: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.footer {
  padding-top: 60px;
  padding-bottom: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #dfdfdf;
  text-align: center;
}

.footer-flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: left;
}

.footer-logo-link {
  max-height: 60px;
  min-width: 60px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.footer-image {
  width: auto;
  height: 50px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.footer-heading {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5;
}

.footer-link {
  display: block;
  margin-bottom: 10px;
  color: #444;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.body {
  font-family: Kiona, sans-serif;
}

.fits-details-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  padding-left: 32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}

.fits-details-link {
  color: #000;
  text-decoration: none;
}

.text-block {
  display: none;
  color: #fff;
}

.div-block-6 {
  width: 400px;
  height: 5px;
  border-radius: 20px;
  background-color: hsla(0, 0%, 100%, 0.12);
}

.cta {
  padding-top: 100px;
  padding-bottom: 100px;
}

.container-2 {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: "Area";
  -ms-grid-columns: 1fr 16px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-7 {
  background-color: #532020;
}

.div-block-8 {
  background-color: #6ab268;
}

.cta-text-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: transparent;
}

.cta-mockup-group {
  position: relative;
  background-color: transparent;
}

.cta-group {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 199px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.cta-bg-img {
  position: absolute;
  left: -224px;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  max-width: 230%;
}

.cta-text-grid {
  display: -ms-grid;
  display: grid;
  justify-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 2px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: transparent;
}

.cta-btn {
  max-width: 300px;
  padding: 12px 30px;
  background-color: #5cc9ad;
  color: white;
}

.spacer-32 {
  height: 16px;
}

.services-body {
  font-family: Kiona, sans-serif;
}

.sticky-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  padding: 20px;
  background-color: #f4f4f4;
}

.nav-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: 1fr max-content max-content max-content;
  grid-template-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: 1fr max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.nav-logo-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-logo {
  width: auto;
  height: 40px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.nav-link {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 0px;
  color: #444;
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: underline;
}

.button-2 {
  background-color: #000;
}

.text-block-3 {
  color: #fff;
  font-size: 17px;
}

.icon-2 {
  color: #fff;
}

.dropdown-list-2 {
  background-color: #fff;
}

.nav-dropdown {
  background-color: #f4f4f4;
}

.nav-dropdown.w--open {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.19);
}

.services {
  padding-top: 100px;
  padding-bottom: 200px;
}

.service-h1 {
  padding-top: 0px;
  padding-bottom: 25px;
  text-align: center;
}

.services-inner-container {
  display: -ms-grid;
  display: grid;
  padding-right: 32px;
  padding-left: 32px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 62px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.service {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.fits-card-seperator {
  height: 1px !important;
  background-color: rgba(0, 0, 0, 0.116);
  width: 80%;
  margin: 16px 0;
}

.service-image-container {
  background-color: transparent;
}

.avatar-initials {
  color: white;
  margin: 0;
  padding: 0;
}

.service-image-container.mobile {
  display: none;
}

.service-text-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.service-description {
  max-width: 500px;
  margin-top: 16px;
  font-size: 16px;
}

.service-cta {
  margin-top: 29px;
  padding: 12px 30px;
  background-color: #131313;

  display: inline-block;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  text-align: center;
  margin: 16px auto;
}

.services-container {
  max-width: 1200px;
}

.service-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 24px;
}

.service-seperator {
  width: 60%;
  height: 2px;
  margin-right: auto;
  margin-bottom: 100px;
  margin-left: auto;
  padding-bottom: 0px;
  background-color: rgba(0, 0, 0, 0.04);
}

.service-title-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-line {
  width: 5px;
  height: 40px;
  background-color: #5cc9ad;
}

.heading-line.pink {
  margin-right: 8px;
  margin-left: 4px;
  background-color: #fab6bb;
}

.discounts {
  padding-top: 100px;
  padding-bottom: 100px;
}

.discount-body {
  font-family: Kiona, sans-serif;
}

.discounts-h1 {
  padding-bottom: 0px;
  font-size: 32px;
  text-align: center;
}

.grid {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.discount-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 280px;
  height: 300px;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-style: solid;
  border-width: 1.2px;
  border-color: rgba(0, 0, 0, 0.1);
}

.image-6 {
  width: 120px;
  height: 120px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.discount-btn {
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #1b1d1c;
  text-align: center;
  color: white;
}

.discount-title {
  font-size: 15px;
  text-align: center;
  /* margin: 0 auto; */
}

.mission {
  padding-top: 100px;
}

.mission-container {
  font-size: 13px;
}

.posts-heading {
  padding-bottom: 60px;
  text-align: center;
}

.discount-img-container {
  width: 100px;
  height: 100px;
  margin-right: auto;
  margin-left: auto;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.17);
  background-color: #f8f8f8;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.discount-img-container._2 {
  background-image: url("../images/TRNDHUT.JPG");
}

.discount-img-container._1 {
  background-image: url("../images/Yemsxo.jpg");
}

.discount-img-container._3 {
  background-image: url("../images/Favelacode.JPG");
}

.discount-img-container._4 {
  background-color: #fff;
  background-image: url("../images/bodyBandage.jpg");
  background-size: contain;
}

.discount-img-container._5 {
  background-image: url("../images/summah.jpeg");
}

.mission-paragraph {
  color: #000;
  font-size: 18px;
  text-align: center;
}

.mission-paragraph.alt {
  margin-top: 32px;
  font-family: "Kiona itallic", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.discounts-group {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.discounts-paragraph {
  margin-bottom: 90px;
  font-size: 17px;
  text-align: center;
}

.account-body {
  font-family: Kiona, sans-serif;
}

.account-grid {
  display: -ms-grid;
  display: grid;
  height: 700px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.account-h1 {
  padding-bottom: 32px;
  font-size: 38px;
}

.account-container {
  padding-top: 32px;
  padding-bottom: 100px;
  max-width: 1000px;
  margin: 16px auto;
}

.account-img {
  background-color: black;
  background-image: url("../images/account-image.jpg");
  background-position: 0px 0px;
  background-size: cover;
}

a:hover {
  text-decoration: none !important;
}

a.cancel-subs-btn:hover {
  background-color: #5cc9ad;
  color: #fff;
}

a.logout-btn:hover {
  color: #c4c4c4;
}

.account-text-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  padding: 64px 34px 34px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.account-avatar {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-color: rgba(165, 146, 123, 0.79);
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-fullname {
  font-size: 21px;
  text-align: center;
}

.account-email {
  text-align: center;
}

.logout-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 55px;
  margin-top: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1.3px solid #000;
  background-color: transparent;
  color: #000;
}

.cancel-subs-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 55px;
  margin-top: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  color: white !important;
}

.del-acc-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 55px;
  margin-top: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgb(182, 2, 2);
  color: white !important;
}

.account-list-text {
  text-align: center;
}

.text-block-9 {
  margin-top: 8px;
  text-align: center;
}

.onboarding-form {
  position: relative;
  height: 100vh;
  margin-top: -71px;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url("../images/f4dblack.png");
  background-position: 0px 0px;
  background-size: cover;
  color: #000;
  min-height: 890px;
}

.service-form-grid {
  display: -ms-grid;
  display: grid;
  margin-top: 40px;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.body-5 {
  font-family: Kiona, sans-serif;
}

.black-bg {
  background-color: black !important;
}

.textarea {
  height: auto;
}

.service-form-submit-btn {
  margin-top: 32px;
  background-color: #000;
}

.body-6 {
  font-family: Kiona, sans-serif;
}

.submit-button-2 {
  background-color: #000;
}

.body-7 {
  font-family: Kiona, sans-serif;
}

.signup-body {
  background-color: #000;
  font-family: Kiona, sans-serif;
}

.image-7 {
  width: 90px;
}

.onboarding-nav-bar {
  top: 40px;
  background-color: transparent;
}

.nav-link-2 {
  color: #000;
}

.onboarding-switch {
  color: #a5a5a5;
}

.onboarding-submit-btn {
  width: 100%;
  margin-top: 32px;
  background-color: #000;
  color: #fff;
  height: 55px;
  border: none;
  text-align: center;
  cursor: pointer;
}

.inner-oboarding-form {
  margin-top: 8px;
}

.onboarding-title {
  text-align: center;
}

.onboarding-paragraph {
  border-radius: 0px;
  text-align: center;
}

.benefits {
  padding-top: 100px;
  padding-bottom: 100px;
}

.benefiits-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.benefiits-grid.mb-64 {
  margin-bottom: 64px;
}

.benefits-para {
  color: #fff;
  font-size: 16px;
}

.benefiits-text-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.benefits-seperator {
  height: 1px;
  margin-top: 48px;
  margin-bottom: 48px;
  border-radius: 20px;
  background-color: hsla(0, 0%, 100%, 0.3);
}
.benefits-seperator-dark {
  height: 1px;
  border-radius: 20px;
  margin: 48px auto;
  width: 50%;
  background-color: hsla(0, 1%, 78%, 0.4);
}

.benefits-seperator.hidden {
  display: none;
}

.benefits-img {
  display: block;
  width: 350px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.benefiits-image-container {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.benefiits-image-container.mobile {
  display: none;
}

.container-6 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.benefits-heading {
  color: #fff;
}

.onboarding-card {
  position: relative;
  z-index: 2;
  max-width: 450px;
  padding: 33px 33px 60px;
  border-radius: 3px;
  background-color: #fff;
  color: #000;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
}

.dim-view {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.77;
  min-height: 890px;
}

.div-block-19 {
  background-color: #d12323;
}

.image-8 {
  width: 70px;
}

.subs-nav {
  top: 32px;
  background-color: transparent;
}

.subscriptions {
  margin-top: 64px;
  padding-bottom: 100px;
}

.subscription-body {
  font-family: Kiona, sans-serif;
}

.subs-p {
  max-width: 650px;
  margin-top: 16px;
  color: rgba(51, 51, 51, 0.61);
  font-size: 16px;
  text-align: center;
}

.subs-h1 {
  color: #000;
  text-align: center;
}

.subscription-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.subscriptions-grid {
  display: -ms-grid;
  display: grid;
  height: 100%;
  margin-top: 64px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.subscription-card {
  width: 300px;
  padding: 36px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.11);
  border-radius: 1px;
}

.subs-grey-sub {
  color: rgba(51, 51, 51, 0.48);
}

.subs-seperator {
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: #e9e9e9;
}

.list-item {
  margin-top: 12px;
}

.text-block-11 {
  margin-top: 12px;
}

.text-block-12 {
  margin-top: 12px;
}

.text-block-13 {
  margin-top: 12px;
}

.list {
  margin-top: 32px;
}

.service-img {
  width: 370px;
  margin-right: auto;
  margin-left: auto;
}

.image-10 {
  width: 380px;
}

.image-11 {
  width: 380px;
}

.service-form-container {
  padding: 32px;
}

.subs-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #5cc9ad;
  text-align: center;
  color: white !important;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.reviews {
  padding-top: 100px;
  padding-bottom: 100px;
}

.reviews-container {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: "Area";
  -ms-grid-columns: 1fr 16px 1fr 16px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
}

.review-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  box-shadow: -3px 12px 20px 2px rgba(0, 0, 0, 0.06);
}

.reviewer-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.reviewer-avatar {
  width: 60px;
  border-radius: 30px;
}

.review-p {
  margin-top: 32px;
  margin-bottom: 32px;
}

.review-handle {
  margin-left: 16px;
}

.review-grid {
  display: -ms-grid;
  display: grid;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

@media screen and (max-width: 991px) {
  .home-nav-dropdown {
    border: none;
    margin: 0 auto;
    /* background-color: tomato; */
  }

  .home-nav-items {
    color: black !important;
    font-size: 15px;
  }

  .home-nav {
    padding: 24px;
    background-color: white;
    position: relative;
  }
  .signup-hero {
    height: 500px;
  }
  .home-nav-btn {
    color: #000;
    text-align: center;
  }
  .home-nav-btn.btn {
    display: block;
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    background-color: #000;
    color: #fff;
  }
  .home-nav-menu {
    left: auto;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 24px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-color: #fff;
  }
  .hero-img {
    height: 500px;
  }
  .icon {
    color: #fff;
  }
  .footer {
    padding-right: 20px;
    padding-left: 20px;
  }
  .cta-group {
    grid-column-gap: 52px;
  }
  .cta-bg-img {
    left: -135px;
    max-width: 190%;
  }
  .cta-heading {
    font-size: 32px;
  }
  .cta-description {
    max-width: 300px;
    font-size: 12px;
  }
  .text-block-3 {
    color: #000;
  }
  .icon-2 {
    color: #000;
  }
  .dropdown-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .services-inner-container {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 62px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .services-container {
    padding-right: 16px;
    padding-left: 16px;
  }
  .service-title-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .div-block-12 {
    width: 3px;
    max-width: 3px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: #fab6bb;
  }
  .discounts {
    padding-top: 32px;
  }
  .mission {
    padding-top: 60px;
  }
  .mission-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .block-quote {
    padding-right: 32px;
    padding-left: 32px;
  }
  .mission-paragraph.alt {
    max-width: 350px;
    font-size: 14px;
  }
  .discounts-group {
    margin-right: 32px;
    margin-left: 32px;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .nav-link-2 {
    color: #000;
  }
  .nav-menu-2 {
    background-color: #fff;
  }
  .reviews-container {
    display: -ms-grid;
    display: grid;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  .review-grid {
    padding-right: 32px;
    padding-left: 32px;
    grid-column-gap: 32px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .signup-hero {
    height: 400px;
  }
  .home-nav-menu {
    left: auto;
  }
  .hero-img {
    height: 400px;
  }
  .posts {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .posts-grid {
    grid-row-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .footer {
    padding: 40px 20px;
  }
  .footer-image {
    -o-object-fit: contain;
    object-fit: contain;
  }
  .div-block-2 {
    display: none;
  }
  .div-block-3 {
    display: none;
  }
  .div-block-4 {
    display: none;
  }
  .cta {
    padding-top: 32px;
    padding-bottom: 64px;
  }
  .cta-mockup-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .cta-group {
    overflow: hidden;
    margin-right: 16px;
    margin-left: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .cta-bg-img {
    left: -224px;
  }
  .cta-text-grid {
    justify-items: center;
  }
  .cta-img {
    max-width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
  .sticky-nav {
    position: relative;
  }
  .nav-grid {
    text-align: center;
  }
  .service-description {
    font-size: 14px;
  }
  .services-container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .service-title {
    font-size: 21px;
  }
  .discounts {
    padding-top: 64px;
  }
  .discount-card {
    width: 250px;
  }
  .mission-container {
    padding-right: 32px;
    padding-left: 32px;
  }
  .posts-heading {
    padding-bottom: 50px;
    text-align: center;
  }
  .discounts-group {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 16px;
    padding-left: 16px;
  }
  .account-grid {
    height: 600px;
    margin-top: 24px;
    margin-right: 48px;
    margin-left: 48px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .account-h1 {
    margin-right: 48px;
    margin-left: 48px;
    font-size: 32px;
    text-align: center;
  }
  .account-img {
    display: none;
  }
  .onboarding-form {
    margin-top: -70px;
    padding: 40px 20px;
  }
  .image-7 {
    width: 70px;
  }
  .onboarding-nav-bar {
    top: 16px;
  }
  .onboarding-submit-btn {
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
  }
  .onboarding-title {
    font-size: 28px;
  }
  .onboarding-paragraph {
    font-size: 12px;
  }
  .benefiits-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .benefits-para {
    font-size: 14px;
    text-align: center;
  }
  .benefits-seperator {
    margin-top: 74px;
    margin-bottom: 0px;
  }
  .benefits-img {
    width: 250px;
  }
  .benefiits-image-container.mobile {
    display: block;
  }
  .benefiits-image-container.web {
    display: none;
  }
  .container-6 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .benefits-heading {
    font-size: 24px;
    text-align: center;
  }
  .onboarding-card {
    margin-top: 87px;
    background-color: rgba(0, 0, 0, 0.56);
    color: #fff;
  }
  .subscriptions {
    padding-bottom: 100px;
  }
  .subs-p {
    font-size: 15px;
  }
  .subs-h1 {
    font-size: 28px;
  }
  .subscription-container {
    padding-right: 32px;
    padding-left: 32px;
  }
  .subscriptions-grid {
    grid-row-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .subscription-card {
    padding: 24px;
  }
  .review-grid {
    grid-row-gap: 48px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  .signup-hero {
    height: 370px;
  }
  .home-nav-menu {
    left: auto;
  }
  .hero-container {
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .hero-img {
    height: 350px;
  }
  .posts {
    padding-top: 32px;
    padding-bottom: 50px;
  }
  .fit-card {
    width: 280px;
    height: 350px;
  }
  .fit-card._1 {
    width: 350px;
  }
  .fit-image-container {
    height: 280px;
  }
  .footer {
    padding-right: 20px;
    padding-left: 20px;
    text-align: left;
  }
  .footer-flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-logo-link {
    height: 60px;
  }
  .footer-heading {
    margin-top: 20px;
  }
  .cta {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .cta-group {
    grid-row-gap: 0px;
  }
  .cta-bg-img {
    left: -128px;
    display: none;
    overflow: hidden;
  }
  .cta-heading {
    font-size: 28px;
  }
  .cta-description {
    font-size: 11px;
    text-align: center;
  }
  .sticky-nav {
    padding: 0px;
  }
  .nav-grid {
    padding-bottom: 25px;
    grid-auto-flow: row;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 100px;
    grid-template-rows: 100px;
  }
  .nav-logo-link {
    border-top: 1px solid #c4c4c4;
  }
  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #c4c4c4;
  }
  .services {
    padding-top: 50px;
  }
  .service-h1 {
    padding-bottom: 20px;
    font-size: 32px;
  }
  .services-inner-container {
    padding-right: 16px;
    padding-left: 16px;
  }
  .service {
    grid-row-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .service-image-container.mobile {
    display: block;
  }
  .service-image-container.web {
    display: none;
  }
  .service-cta {
    margin-right: auto;
    margin-left: auto;
  }
  .services-container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .service-title {
    font-size: 24px;
  }
  .service-seperator {
    margin-bottom: 50px;
  }
  .service-title-group {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .discounts {
    padding-top: 32px;
  }
  .discounts-h1 {
    font-size: 28px;
  }
  .mission {
    padding-top: 50px;
  }
  .mission-container {
    padding-right: 16px;
    padding-left: 16px;
  }
  .block-quote {
    padding-right: 8px;
    padding-left: 8px;
    font-size: 14px;
  }
  .mission-paragraph.alt {
    font-size: 12px;
  }
  .discounts-group {
    grid-row-gap: 47px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .discounts-paragraph {
    margin-right: 16px;
    margin-left: 16px;
    font-size: 14px;
  }
  .account-grid {
    height: 500px;
    margin-right: 16px;
    margin-left: 16px;
  }
  .account-h1 {
    text-align: center;
  }
  .account-text-grid {
    padding: 32px 16px 0px;
  }
  .image-7 {
    width: 60px;
  }
  .onboarding-submit-btn {
    margin-top: 16px;
  }
  .inner-oboarding-form {
    margin-top: 48px;
  }
  .onboarding-title {
    font-size: 21px;
  }
  .onboarding-paragraph {
    margin-bottom: 0px;
  }
  .benefits-seperator {
    margin-top: 48px;
    margin-bottom: 16px;
  }
  .container-6 {
    grid-row-gap: 16px;
  }
  .onboarding-card {
    padding: 16px;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.71);
  }
  .onboarding-form-block {
    margin-top: -8px;
  }
  .subs-p {
    font-size: 13px;
  }
  .subs-h1 {
    font-size: 21px;
  }
  .reviews {
    padding-top: 24px;
  }
  .review-card {
    padding: 24px;
  }
  .reviewer-avatar {
    width: 45px;
  }
  .review-p {
    font-size: 12px;
  }
  .review-handle {
    margin-left: 8px;
    font-size: 14px;
  }
}

#w-node-a05d0d02498b-fb7198e9 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-141fb7dfbb63-fb7198e9 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c53195a23fd1-95a23fca {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c53195a23fdc-95a23fca {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c53195a23fe1-95a23fca {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c53195a23fef-95a23fca {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-161480ae9dbf-80ae9dbc {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-77965b1153b6-02c2a9c2 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-4fcc95836201-02c2a9c2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-070e8567e116-02c2a9c2 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 3;
  grid-row-start: span 3;
  -ms-grid-row-span: 3;
  grid-row-end: span 3;
}

#w-node-faea0385787a-a1f8f753 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-e1cb7d196605-925c45d8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5d7ce5c45fff-1b54c4c2 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-41190d05a77d-31a4f95d {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-6a597ab152e9-31a4f95d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-25d0d035448b-31a4f95d {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-3b07adf919ef-31a4f95d {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-3b07adf919f4-31a4f95d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-35f741ddc961-31a4f95d {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-35f741ddc966-31a4f95d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-47585aab7ecb-c2871bb6 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-5b24b5affdf1-c2871bb6 {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

@media screen and (max-width: 767px) {
  #w-node-a05d0d02498b-fb7198e9 {
    -ms-grid-column-align: center;
    justify-self: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #w-node-070e8567e116-02c2a9c2 {
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
  }
}

@media screen and (max-width: 479px) {
  #w-node-161480ae9dbf-80ae9dbc {
    -ms-grid-column-align: center;
    justify-self: center;
  }
  #w-node-faea0385786a-a1f8f753 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-faea0385786e-a1f8f753 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-faea03857872-a1f8f753 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-faea03857876-a1f8f753 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-e1cb7d1965f5-925c45d8 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-e1cb7d1965f9-925c45d8 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-e1cb7d1965fd-925c45d8 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-e1cb7d196601-925c45d8 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-5d7ce5c45fef-1b54c4c2 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-5d7ce5c45ff3-1b54c4c2 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-5d7ce5c45ff7-1b54c4c2 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-5d7ce5c45ffb-1b54c4c2 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-a1cf24ecc189-1b54c4c2 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
}

@font-face {
  font-family: "Kiona itallic";
  src: url("../fonts/Kiona-Itallic.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Kiona";
  src: url("../fonts/Kiona-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
